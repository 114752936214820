<template>
    <SimpleSuspensionsTable
      :title="$t('suspensions.table.title')"
      :items="suspensions"
      :loading="isLoading"
      @itemDeleted="onSuspensionDeleted"
      @itemUpdated="onSuspensionUpdated"
      @sortingChanged="onSortingChanged"
      :features="features"
    />
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import SimpleSuspensionsTable from '@/components/tables/SimpleSuspensionsTable'

export default {
  components: { SimpleSuspensionsTable },
  emits: ['suspensionDeleted', 'suspensionUpdated'],
  props: {
    userGroup: { type: Object, required: true }
  },
  data () {
    return {
      suspensions: [],
      isLoading: true,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['activate', 'update', 'delete'] : ['activate', 'update']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('suspensions', ['fetchSuspensionsByUserGroupId']),
    onSuspensionDeleted (item) {
      this.removeFromSuspensions(item.id)
      this.$emit('suspensionDeleted', item)
    },
    onSuspensionUpdated (item) {
      // Try to find the updated suspension among the currently shown suspensions.
      const index = this.suspensions.findIndex((userGroup) => userGroup.id === item.id)
      if (index !== -1) {
        // Update the suspension to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.suspensions, index, item)
      }
      this.$emit('suspensionUpdated', item)
    },
    removeFromSuspensions (id) {
      // Try to find the item among the currently selected items.
      const index = this.suspensions.findIndex((item) => item.id === id)
      if (index !== -1) {
        // Delete the item from the selected items.
        this.suspensions.splice(index, 1)
      }
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchSuspensionsDebounced(this.userGroup.id, newSorting)
    },
    fetchSuspensionsDebounced: debounce(async function (userGroupId, sorting) {
      await this.fetchSuspensions(userGroupId, sorting)
    }, 250),
    async fetchSuspensions (userGroupId, sorting) {
      try {
        this.isLoading = true
        this.suspensions = await this.fetchSuspensionsByUserGroupId({ userGroupId, sorting })
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    userGroup: {
      immediate: true,
      deep: true,
      async handler (newVal, oldVal) {
        await this.fetchSuspensionsDebounced(newVal.id, this.latestSorting)
      }
    }
  }
}
</script>
