<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card :loading="isLoading">
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- Name -->
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :label="$t('usergroups.dialog.name-label')"
                  :error-messages="validationErrors['name']"
                  :error-count="5"
                />
              </v-col>

              <!-- Systems -->
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('usergroups.dialog.systems-label')"
                  :search-input.sync="systemSearchString"
                  @change="systemSearchString = ''"
                  v-model="selectedSystemIds"
                  :items="systems"
                  item-text="name"
                  item-value="id"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  class="pa-0 ma-0"
                  persistent-hint
                  :error-messages="validationErrors['systemIds']"
                  :error-count="3"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
            :disabled="isLoading"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    userGroup: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      name: '',
      isSaving: false,
      selectedSystemIds: [],
      systemSearchString: '',
      validationErrors: {},
      lastSuccessfulLoad: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState('systems', ['systems']),
    ...mapGetters('systems', ['getSystemsByIds']),
    isNew () {
      return this.userGroup == null
    },
    refetchImminent () {
      return !this.lastSuccessfulLoad || Date.now() - this.lastSuccessfulLoad > 60 * 1000
    },
    formTitle () {
      return this.$t(this.isNew ? 'usergroups.dialog.create-title' : 'usergroups.dialog.edit-title')
    },
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('systems', ['fetchAllSystems']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}

      try {
        // Send a request to create/update the item.
        const response = this.isNew ? await this.createNew() : await this.updateExisiting()
        // Notify the dialog has saved the item.
        const event = this.isNew ? 'itemCreated' : 'itemUpdated'
        this.$emit(event, response.data)
        // Show a message indicating the save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.usergroup') })],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.usergroup') })],
            duration: 5000
          })
        }
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const payload = {
        id: this.userGroup.id,
        name: this.name,
        userIds: this.userGroup.userIds,
        systemIds: this.selectedSystemIds
      }
      return await axios.put('userGroups', payload)
    },
    async createNew () {
      const payload = {
        name: this.name,
        userIds: [],
        systemIds: this.selectedSystemIds
      }
      return await axios.post('userGroups', payload)
    },
    async fetchData () {
      try {
        this.isLoading = true
        await this.fetchAllSystems()
        this.lastSuccessfulLoad = Date.now()
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
        this.close()
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      async handler (newVal, oldVal) {
        // Only update the values if the dialog is being opened.
        if (newVal) {
          this.name = this.userGroup?.name ? this.userGroup.name : ''
          this.selectedSystemIds = this.userGroup ? this.userGroup.systemIds : []
          this.validationErrors = {}

          if (this.refetchImminent) await this.fetchData()
        }
      }
    }
  }
}
</script>
