<template>
  <div>
    <UserGroupsTable
      :items="userGroups"
      :total-size="totalSize"
      :page-size="pageSize"
      :page-number="pageNumber"
      :loading="isLoading"
      :title="$t('usergroups.table.title')"
      @pageChanged="pageChanged"
      @itemCreated="onItemCreated"
      @itemUpdated="onItemUpdated"
      @itemDeleted="onItemDeleted"
      @sortingChanged="onSortingChanged"
      @refreshRequested="onRefreshRequested"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions } from 'vuex'
import UserGroupsTable from '@/components/tables/UserGroupsTable'

export default {
  components: { UserGroupsTable },
  data () {
    return {
      userGroups: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('userGroups', ['fetchUserGroups']),
    onItemUpdated (updatedUserGroup) {
      // Try to find the updated user group among the currently shown userGroups.
      const index = this.userGroups.findIndex((userGroup) => userGroup.id === updatedUserGroup.id)
      if (index !== -1) {
        // Update the user group to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.userGroups, index, updatedUserGroup)
      }
    },
    async onItemCreated (newUserGroup) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onItemDeleted (deletedUserGroup) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        // Fetch all user groups.
        const pagedResponse = await this.fetchUserGroups({ pageNumber, pageSize, sorting })
        this.userGroups = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
